import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Button, ButtonColor, ButtonTextTransform, ButtonType, Card, Form } from 'react-magma-dom';
import { ExtendedDueDateStub } from './ExtendedDueDateStub';
import { PenaltiesStub } from './PenaltiesStub';
import { AdditionalSubmissionsStub } from './AdditionalSubmissionsStub';
import { AdditionalTime } from './additionalTime/AdditionalTime';
import { useTranslation } from 'react-i18next';
import { EditType, IEditFormData } from 'context/ExtensionsContextProvider/types';

const StyledCard = styled(Card)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.565rem 1.875rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.colors.lightGrey};
  box-sizing: border-box;
  margin-left: 2em;
  min-height: 100%; /* Ensures it takes the full height of the parent */
`;

const StyledForm = styled(Form)`
  background: ${({ theme }) => theme.colors.lightGrey};
  h5 {
    margin: 0 0 1.5rem 0;
  }
`;

const Row = styled.div<{ isTimedAssignment?: boolean }>`
  display: flex;
  gap: ${({ isTimedAssignment }) => (isTimedAssignment ? '3rem' : '7rem')};
  flex-wrap: wrap;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
`;

interface IEditExtensionsProps {
  isTimedAssignment: boolean;
  isUnlimitedTimeAccommodation?: boolean;
}

export const EditExtensions = (props: IEditExtensionsProps): JSX.Element => {
  const { isTimedAssignment, isUnlimitedTimeAccommodation } = props;
  const { t } = useTranslation();

  const initialState: IEditFormData = {
    extendedDueDate: undefined,
    extendedDueTime: undefined,
    timeAccommodation: undefined,
    numberOfSubmissions: undefined,
    penaltyOption: t('EXTENSIONS.PENALTY_UNEARNED_OPTION'),
    penaltyValue: undefined,
  };

  const [editFormData, setEditFormData] = useState<IEditFormData>(initialState);

  const handleChange = <T extends keyof IEditFormData>(field: T, value: IEditFormData[T]) => {
    setEditFormData(formData => ({
      ...formData,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setEditFormData(initialState);
  };

  return (
    <div>
      <StyledCard>
        <StyledForm
          header={t('EXTENSIONS.EDIT_HEADER')}
          headingLevel={5}
          onReset={handleReset}
          actions={
            <ButtonContainer>
              <Button
                type={ButtonType.reset}
                color={ButtonColor.secondary}
                textTransform={ButtonTextTransform.uppercase}
              >
                {t('EXTENSIONS.BUTTON.RESET')}
              </Button>
              <Button color={ButtonColor.primary} textTransform={ButtonTextTransform.uppercase}>
                {t('EXTENSIONS.BUTTON.SAVE')}
              </Button>
            </ButtonContainer>
          }
        >
          <Row isTimedAssignment={isTimedAssignment}>
            <Column>
              <ExtendedDueDateStub
                dueDate={editFormData.extendedDueDate}
                dueTime={editFormData.extendedDueTime}
                setDueDate={(dueDate?: Date) => handleChange('extendedDueDate', dueDate)}
                setDueTime={(dueTime?: string) => handleChange('extendedDueTime', dueTime)}
              />
              {!isTimedAssignment && (
                <PenaltiesStub
                  penaltyOption={editFormData.penaltyOption}
                  penaltyValue={editFormData.penaltyValue}
                  setPenaltyOption={(option?: string) => handleChange('penaltyOption', option)}
                  setPenaltyValue={(value?: number) => handleChange('penaltyValue', value)}
                />
              )}
              {isTimedAssignment && (
                <AdditionalSubmissionsStub
                  submissions={editFormData.numberOfSubmissions}
                  setNumberOfSubmissions={(submissions?: number) => handleChange('numberOfSubmissions', submissions)}
                />
              )}
            </Column>
            <Column>
              {!isTimedAssignment && (
                <AdditionalSubmissionsStub
                  submissions={editFormData.numberOfSubmissions}
                  setNumberOfSubmissions={(submissions?: number) => handleChange('numberOfSubmissions', submissions)}
                />
              )}
              {isTimedAssignment && (
                <AdditionalTime
                  timeAccommodation={editFormData.timeAccommodation}
                  onChange={(value?: number) => handleChange('timeAccommodation', value)}
                  editType={EditType.EDIT}
                  baseTime={60}
                  studentTimeRemaining={50}
                  isUnlimitedTimeAccommodation={isUnlimitedTimeAccommodation}
                />
              )}
              {isTimedAssignment && (
                <PenaltiesStub
                  penaltyOption={editFormData.penaltyOption}
                  penaltyValue={editFormData.penaltyValue}
                  setPenaltyOption={(option?: string) => handleChange('penaltyOption', option)}
                  setPenaltyValue={(value?: number) => handleChange('penaltyValue', value)}
                />
              )}
            </Column>
          </Row>
        </StyledForm>
      </StyledCard>
    </div>
  );
};
