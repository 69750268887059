import styled from '@emotion/styled';
import React, { useState } from 'react';
import {
  Badge,
  Button,
  ButtonColor,
  ButtonTextTransform,
  ButtonType,
  Card,
  Flex,
  FlexBehavior,
  FlexDirection,
  Form,
} from 'react-magma-dom';
import { useTranslation } from 'react-i18next';
import { ExtendedDueDateStub } from './ExtendedDueDateStub';
import { AdditionalTime } from './additionalTime/AdditionalTime';
import { AdditionalSubmissionsStub } from './AdditionalSubmissionsStub';
import { PenaltiesStub } from './PenaltiesStub';

import { BulkEditType, EditType, IEditFormData } from 'context/ExtensionsContextProvider/types';

const StyledCard = styled(Card)`
  max-width: 81rem;
  padding: 1.565rem 1.875rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.colors.lightGrey};
  box-sizing: border-box;
`;

const StyledForm = styled(Form)`
  background: ${({ theme }) => theme.colors.lightGrey};
  h5 {
    margin: 0 0 1.5rem 0;
  }
`;

const InputContainer = styled.div<{ isTimedAssignment?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ isTimedAssignment }) => (isTimedAssignment ? 'space-between' : 'flex-start')};
  gap: ${({ isTimedAssignment }) => (isTimedAssignment ? '' : '3.5rem')};
  min-height: 9rem;
  margin-bottom: 2rem;
`;

const StyledBadge = styled(Badge)`
  margin-left: 10px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  height: 24px;
  padding-top: 3px;
`;

type BulkEditExtensionsProps = {
  isTimedAssignment: boolean;
  bulkEditType: BulkEditType;
  isUnlimitedTimeAccommodation?: boolean;
};

export const BulkEditExtensions: React.FC<BulkEditExtensionsProps> = (props: BulkEditExtensionsProps) => {
  const { t } = useTranslation();
  const { isTimedAssignment, bulkEditType, isUnlimitedTimeAccommodation } = props;

  const initialState: IEditFormData = {
    extendedDueDate: undefined,
    extendedDueTime: undefined,
    timeAccommodation: undefined,
    numberOfSubmissions: undefined,
    penaltyOption: t('EXTENSIONS.PENALTY_UNEARNED_OPTION'),
    penaltyValue: undefined,
  };

  const [bulkEditFormData, setBulkEditFormData] = useState<IEditFormData>(initialState);

  const handleChange = <T extends keyof IEditFormData>(field: T, value: IEditFormData[T]) => {
    setBulkEditFormData(formData => ({
      ...formData,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setBulkEditFormData(initialState);
  };
  const selectedStub = 2;

  return (
    <div>
      <StyledCard>
        <StyledForm
          header={t('EXTENSIONS.BULK_EDIT_HEADER')}
          headingLevel={5}
          onReset={handleReset}
          actions={
            <div>
              <Button
                type={ButtonType.reset}
                color={ButtonColor.secondary}
                textTransform={ButtonTextTransform.uppercase}
              >
                {t('EXTENSIONS.BUTTON.RESET')}
              </Button>
              <Button color={ButtonColor.primary} textTransform={ButtonTextTransform.uppercase}>
                {bulkEditType === BulkEditType.STUDENT
                  ? t('EXTENSIONS.BUTTON.SAVE_TO_SELECTED_STUDENTS')
                  : t('EXTENSIONS.BUTTON.SAVE_TO_SELECTED_ASSIGNMENTS')}
                <StyledBadge>{selectedStub}</StyledBadge>
              </Button>
            </div>
          }
        >
          <InputContainer isTimedAssignment={isTimedAssignment}>
            <Flex behavior={FlexBehavior.item} direction={FlexDirection.column}>
              <ExtendedDueDateStub
                dueDate={bulkEditFormData.extendedDueDate}
                dueTime={bulkEditFormData.extendedDueTime}
                setDueDate={(dueDate?: Date) => handleChange('extendedDueDate', dueDate)}
                setDueTime={(dueTime?: string) => handleChange('extendedDueTime', dueTime)}
              ></ExtendedDueDateStub>
            </Flex>
            {isTimedAssignment && (
              <Flex behavior={FlexBehavior.item} direction={FlexDirection.column}>
                <AdditionalTime
                  timeAccommodation={bulkEditFormData.timeAccommodation}
                  isUnlimitedTimeAccommodation={isUnlimitedTimeAccommodation}
                  onChange={(value?: number) => handleChange('timeAccommodation', value)}
                  editType={EditType.BULK_EDIT}
                  bulkEditType={bulkEditType}
                  baseTime={60}
                  studentTimeRemaining={50}
                />
              </Flex>
            )}
            <Flex behavior={FlexBehavior.item} direction={FlexDirection.column}>
              <AdditionalSubmissionsStub
                submissions={bulkEditFormData.numberOfSubmissions}
                setNumberOfSubmissions={(submissions?: number) => handleChange('numberOfSubmissions', submissions)}
              ></AdditionalSubmissionsStub>
            </Flex>
            <Flex behavior={FlexBehavior.item} direction={FlexDirection.column}>
              <PenaltiesStub
                penaltyOption={bulkEditFormData.penaltyOption}
                penaltyValue={bulkEditFormData.penaltyValue}
                setPenaltyOption={(option?: string) => handleChange('penaltyOption', option)}
                setPenaltyValue={(value?: number) => handleChange('penaltyValue', value)}
              ></PenaltiesStub>
            </Flex>
          </InputContainer>
        </StyledForm>
      </StyledCard>
    </div>
  );
};
