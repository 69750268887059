import React, { useEffect, useState } from 'react';
import {
  ButtonColor,
  ButtonSize,
  Dropdown,
  DropdownAlignment,
  DropdownButton,
  DropdownContent,
  DropdownDropDirection,
  DropdownMenuItem,
} from 'react-magma-dom';
import { IAssignmentInfo } from 'context/ExtensionsContextProvider/types';
import { getAssignmentsInSection } from '../../../../pages/extensions/apiHelpers';
import { useHistory } from 'react-router-dom';

type AssignmentDropDownProps = {
  sectionId: string;
  currentAssignmentId: string;
};

const mergeAssignments = (arrays: any): IAssignmentInfo[] => {
  return [...arrays?.currentAssignments, ...arrays?.pastAssignments];
};

const removeCurrentAssignment = (assignments: IAssignmentInfo[], id: string): IAssignmentInfo[] => {
  return assignments.filter(assignment => assignment.assignmentId !== id);
};

export const AssignmentDropDown: React.FC<AssignmentDropDownProps> = (props: AssignmentDropDownProps) => {
  const { sectionId, currentAssignmentId } = props;
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [assignments, setAssignments] = useState<IAssignmentInfo[]>([]);

  useEffect(() => {
    if (!sectionId) return;
    const fetchAssignmentsData = async () => {
      try {
        const response = await getAssignmentsInSection(sectionId, false);
        setAssignments(removeCurrentAssignment(mergeAssignments(response.data.result), currentAssignmentId));
      } catch (error) {
        console.error('Failed to fetch assignments data:', error);
      }
    };
    fetchAssignmentsData().finally(() => {
      setLoading(false);
    });
  }, [sectionId, currentAssignmentId]);

  const handleAssignmentClick = (deploymentId: string, sectionId: string) => {
    setLoading(true);
    history.push(`/app/extensions/deployment/${deploymentId}/section/${sectionId}`);
  };

  return (
    <>
      <Dropdown alignment={DropdownAlignment.end} dropDirection={DropdownDropDirection.down}>
        <DropdownButton
          aria-label={'Change Assignment'}
          color={ButtonColor.secondary}
          size={ButtonSize.medium}
          disabled={loading}
        >
          Change Assignment
        </DropdownButton>
        <DropdownContent>
          {assignments.map(assignment => (
            <DropdownMenuItem
              key={assignment.assignmentId}
              onClick={() => handleAssignmentClick(assignment.deploymentId, sectionId)}
            >
              {assignment.name}
            </DropdownMenuItem>
          ))}
        </DropdownContent>
      </Dropdown>
    </>
  );
};
