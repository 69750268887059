import React, { useEffect, useState } from 'react';
import { DatePicker, Flex, FlexBehavior, TimePicker } from 'react-magma-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const StyledTimePicker = styled(TimePicker)`
  & > div {
    height: 2.625rem;
  }
`;
const datePickerContainerStyle: React.CSSProperties = { width: '12rem', whiteSpace: 'nowrap', boxSizing: 'border-box' };
const timePickerContainerStyle: React.CSSProperties = { width: '11rem', boxSizing: 'border-box' };

interface IExtendedDueDateProps {
  dueDate?: Date;
  dueTime?: string;
  setDueDate: (dueDate?: Date) => void;
  setDueTime: (dueTime?: string) => void;
}

export const ExtendedDueDateStub = (props: IExtendedDueDateProps): JSX.Element => {
  const { t } = useTranslation();
  const [timeKey, setTimeKey] = useState(false);
  const { dueDate, dueTime, setDueDate, setDueTime } = props;

  const handleExtendedDueDateChange = (value?: Date) => {
    setDueDate(value);
  };

  const handleExtendedDueTimeChange = (value?: string) => {
    setDueTime(value);
  };

  useEffect(() => {
    if (dueTime === undefined) {
      setTimeKey(value => !value);
    }
  }, [dueTime]);

  const dueDateStub = '10/26/2024';
  const dueTimeStub = '6:00 AM EST';

  return (
    <Flex behavior={FlexBehavior.container} spacing={1}>
      <DatePicker
        labelText={t('EXTENSIONS.EXTENDED_DUE_DATE_LABEL')}
        helperMessage={t('EXTENSIONS.EXTENDED_DUE_DATE_HELPER_MESSAGE', {
          dueDate: dueDateStub,
          dueTime: dueTimeStub,
        })}
        containerStyle={datePickerContainerStyle}
        onDateChange={handleExtendedDueDateChange}
        value={dueDate}
        key={dueDate?.toString() ?? ''}
      />
      <StyledTimePicker
        labelText={t('EXTENSIONS.EXTENDED_DUE_TIME_LABEL')}
        containerStyle={timePickerContainerStyle}
        onChange={handleExtendedDueTimeChange}
        value={dueTime}
        key={timeKey.toString()}
      />
    </Flex>
  );
};
