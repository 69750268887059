import { t } from 'i18next';
import React from 'react';

export const checkMinMax = (
  value: number,
  min: number,
  max: number,
  minMessage = '',
  maxMessage = ''
): { value: boolean; message: string } => {
  return {
    value: value < min || value > max,
    message: value < min ? minMessage : maxMessage,
  };
};

export const checkIsWholeNumber = (value: string, message: string): { value: boolean; message: string } => {
  const numberLength = value.split('.').length;
  return { value: numberLength > 1, message: numberLength > 1 ? message : '' };
};
export const checkIsDecimalOnly = (value: string, message: string): { value: boolean; message: string } => {
  const amountOfNumberAfterDot = value.split('.')[1] ? value.split('.')[1].length : 1;
  return { value: amountOfNumberAfterDot > 1, message: amountOfNumberAfterDot > 1 ? message : '' };
};

export const validateTimeAccommodationInput = (input: string): { error: boolean; message: string } => {
  let message = '';
  let error = false;

  if (input === '') return { error, message };

  if (hasLeadingZeros(input)) {
    error = true;
    message = t('EXTENSIONS.ADD_TIME.ERROR.NOT_IN_RANGE');
  }

  const parsedValue = parseInt(input);
  if (parsedValue < 1 || parsedValue > 1000) {
    error = true;
    message = t('EXTENSIONS.ADD_TIME.ERROR.NOT_IN_RANGE');
  }

  return { error, message };
};

export const allowOnlyDecimalInput = (event: React.FormEvent<HTMLInputElement>): void => {
  const input = (event.nativeEvent as InputEvent).data;
  const containsOnlyDecimal = /^\d+$/;

  if (input && !containsOnlyDecimal.test(input)) {
    event.preventDefault();
  }
};

const hasLeadingZeros = (input: string) => {
  return /^0\d+/.test(input);
};

export const getDueDateTimeFromTimeRemaining = (timeRemaining?: number): null | string => {
  if (!timeRemaining) return null;
  const dueDateTime = new Date(Date.now() + timeRemaining * 1000);

  const formattedDueDateTime = new Intl.DateTimeFormat(navigator.language, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short',
  }).format(dueDateTime);

  return formattedDueDateTime.replace(',', '');
};
