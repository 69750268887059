interface IHeaderAssignmentInfo {
  assignmentId: string;
  assignmentName: string;
  assignmentRestrictions?: string;
  assignmentTimed: boolean;
  assignmentSectionId: string;
}

interface IHeaderStudentInfo {
  studentId: string;
  studentFullname: string;
  studentMultiplier?: string;
  hasSeenKeys?: boolean;
}

interface IAssignmentInfo {
  assignmentId: string;
  deploymentId: string;
  name: string;
}

interface IAssignmentsData {
  currentAssignments: IAssignmentInfo[];
  pastAssignments: IAssignmentInfo[];
}

interface IStudentInfo {
  studentId: string;
  studentFullname: string;
}

interface IEditFormData {
  extendedDueDate?: Date;
  extendedDueTime?: string;
  timeAccommodation?: number;
  numberOfSubmissions?: number;
  penaltyOption?: string;
  penaltyValue?: number;
}

enum EditType {
  EDIT = 'edit',
  BULK_EDIT = 'bulkEdit',
}

enum BulkEditType {
  ASSIGNMENT = 'assignment',
  STUDENT = 'student',
}

enum RestrictionLevel {
  DEPLOYMENT = 'deployment',
  SECTION = 'section',
}

export {
  IEditFormData,
  EditType,
  BulkEditType,
  IHeaderAssignmentInfo,
  IHeaderStudentInfo,
  IAssignmentsData,
  IAssignmentInfo,
  IStudentInfo,
  RestrictionLevel,
};
